<template>
  <el-container class="container">
    <el-header>
      <h1>箱运通管理端</h1>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ userinfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="out">退出账号</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu class="el-menu-vertical-demo" :default-active="$route.path" router>
          <!-- <el-menu-item index="/order" title="111">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单信息</span>
          </el-menu-item> -->
          <el-submenu index="0" v-if="ruleList.includes('order')">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>订单信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/order?type=1" title="订单信息" v-if="ruleList.includes('order1')">产装订单</el-menu-item>
              <el-menu-item index="/order?type=2" title="订单信息" v-if="ruleList.includes('order2')">内装订单</el-menu-item>
              <el-menu-item index="/recycleBin" title="订单回收站" v-if="ruleList.includes('recycleBin')">订单回收站</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="1" v-if="ruleList.includes('ziliao')">
            <template slot="title">
              <i class="el-icon-s-check"></i>
              <span>资料管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/customer" title="客户管理" v-if="ruleList.includes('customer')">客户管理</el-menu-item>
              <el-menu-item index="/shipManage" title="船公司管理" v-if="ruleList.includes('shipManage')">船公司管理</el-menu-item>
              <el-menu-item index="/license" v-if="ruleList.includes('license')">工厂管理</el-menu-item>
              <el-menu-item index="/address" v-if="ruleList.includes('address')">产装地址管理</el-menu-item>
              <el-menu-item index="/cost" v-if="ruleList.includes('cost')">堆场/仓库管理</el-menu-item>
              <el-menu-item index="/Partner" v-if="ruleList.includes('Partner')">品名管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-check"></i>
              <span>财务管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/bill" title="账单查询">账单查询</el-menu-item>
              <el-menu-item index="/reconciliation" title="对账查询">对账查询</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
          <!-- <el-menu-item index="/bill">
            <i class="el-icon-s-finance"></i>
            <span slot="title">账单查询</span>
          </el-menu-item> -->
          <el-submenu index="3" v-if="ruleList.includes('system')">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>系统设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/settingCompany" v-if="ruleList.includes('settingCompany')">角色管理</el-menu-item>
              <el-menu-item index="/setting" v-if="ruleList.includes('setting')">个人信息</el-menu-item>
              <el-menu-item index="/user" v-if="ruleList.includes('user')">用户管理</el-menu-item>
              <el-menu-item index="/userMember" v-if="ruleList.includes('userMember')">会员管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- <el-tabs :value="editableTabsValue" type="card" closable>
          <el-tab-pane v-for="tab in $store.state.tabs" :key="tab.name" :label="tab.name">
          </el-tab-pane>
        </el-tabs> -->
        <tabComponet></tabComponet>
        <keep-alive>
          <div style="background-color: white">
            <router-view />
          </div>

        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import tabComponet from '@/components/tabComponets.vue';
import { LoginOut } from '@/api/login/login'
export default {
  name: 'HomeView',
  components: {
    tabComponet
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('addTab', { name: to.name, title: to.meta.title });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('removeTab', from.name);
    next();
  },
  data: function () {
    return {
      userinfo: {},
      ruleList: []
    }
  },
  mounted() {
    document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
    if (localStorage.getItem('userinfo')) {
      this.userinfo = JSON.parse(localStorage.getItem('userinfo'))
      this.ruleList = JSON.parse(localStorage.getItem('rule'))
    }
  },
  methods: {
    handleCommand(command) {
      this.$confirm('确定退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        LoginOut({}).then(res => {
          console.log(res)
          if (res.code == 1) {
            localStorage.removeItem('userinfo')
            localStorage.removeItem('token')
            localStorage.removeItem('rule')
            this.$message.success('退出成功');
            this.$router.push('/login')
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => { });
    }
  }
}
</script>

<style>
.el-header {
  color: #fff;
  text-align: center;
  background: #0174f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  color: #333;
  height: 100%;
}

.el-main {
  color: #333;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
